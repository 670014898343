<script>
import hash from "object-hash";
export default {
  data() {
    return {
      unsavedChangesModal: null,
      savedObjectHash: null,
    };
  },
  methods: {
    setObjectHash(watchedObject) {
      this.savedObjectHash = this.generateObjectHash(watchedObject);
      // Dismiss any open unsaved changes toast
      this.$toast.dismiss(this.unsavedChangesModal);
      this.unsavedChangesModal = null;
    },
    confirmLeaving(evt) {
      if (this.unsavedChangesModal != undefined) {
        const unsaved_changes_warning =
          "You have unsaved changes. Are you sure you wish to leave?";
        evt.returnValue = unsaved_changes_warning;
        return unsaved_changes_warning;
      }
    },
    notifyUnsavedChanges(watchedObject) {
      if (this.savedObjectHash == this.generateObjectHash(watchedObject)) {
        // The user reverted the changes before saving
        this.$toast.dismiss(this.unsavedChangesModal);
        this.unsavedChangesModal = null;
        return;
      }
      if (this.unsavedChangesModal !== null) {
        return;
      }
      this.unsavedChangesModal = this.$toast.warning(
        "You have unsaved changes",
        {
          position: "bottom-right",
          timeout: false,
          closeButton: false,
          closeOnClick: false,
          draggable: false,
        }
      );
    },
    generateObjectHash(watchedObject) {
      return hash(watchedObject);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesModal == undefined) {
      next();
      return;
    }
    if (
      confirm(
        "Are you sure you want to leave the page? You have unsaved changes"
      )
    ) {
      this.$toast.dismiss(this.unsavedChangesModal);
      this.unsavedChangesModal == undefined;
      next();
      return;
    }
  },
  created() {
    window.addEventListener("beforeunload", this.confirmLeaving);
  },
};
</script>
