<template>
  <div v-if="automation.model">
    <div class="row align-items-center" style="height: 30px;">
      <div class="col-3">
        <div v-if="loading">
          <div class="spinner-border spinner-border-sm ms-2" role="status"></div>
        </div>

        <div v-else-if="count">
          {{count}}/{{totalModels}} models meet the conditions
          <span @click="previewModels()" class="link-icon">
            <i class="fa fa-eye"></i>
          </span>
        </div>

        <div v-else>No models meet the conditions at this time</div>
      </div>

      <div class="col-auto" title="Pretended Time">
        <div class="link-icon" @click="toggleTimePicker()">
          <i class="fa-solid fa-clock-rotate-left"></i>
        </div>
      </div>

      <div class="col-2 hidden align-items-center" ref="timepicker">
        <input v-model="time" type="datetime-local" class="form form-control" style="position: relative; z-index: 2;" />
      </div>
      <div class="col-auto">
        <a href="#" @click="pretendedTime = time" class="ms-3" v-if="time">Set</a>
        <a href="#" @click="pretendedTime = null, time = null" class="ms-3" v-if="pretendedTime">Reset</a>
      </div>
    </div>

    <general-modal ref="modelsList" :fullWidth="true" :fullHeight="true">
      <template v-slot:title>Models List</template>
      <template v-slot:body>
        <div style="overflow: auto;">
          <div class="row">
            <div class="col-auto me-1">Pretended Time:</div>
            <div class="col-2">
              <input v-model="time" type="datetime-local" class="form form-control" />
            </div>
            <div class="col">
              <a href="#" @click="pretendedTime = time" class="ms-3" v-if="time">Set</a>
              <a href="#" @click="pretendedTime = null, time = null" class="ms-3" v-if="pretendedTime">Reset</a>
            </div>
          </div>

          <div v-if="loading">
            <div class="row">
              <div class="col">
                <div class="spinner-border spinner-border-sm" role="status"></div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col">
                <table class="table table-striped" v-if="automation.model == 'User'">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Admin</th>
                      <th>Marketing Opt In</th>
                      <th>Created at</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="model in models.data" :key="model.id">
                      <td>{{model.id}}</td>
                      <td>{{model.name}}</td>
                      <td>{{model.email}}</td>
                      <td>{{model.is_registered ? 'registered' : 'guest'}}</td>
                      <td v-html="model.is_admin ? `<i class='fa fa-check'></i>` : ''"></td>
                      <td v-html="model.marketing_opt_in ? `<i class='fa fa-check'></i>` : ''"></td>
                      <td>{{formatValue(model.created_at, 'longDate')}}</td>
                      <td>
                        <button class="vave-btn btn-small btn-blue" :onclick="`window.open('/customers/`+model.id+`', '_blank')`">
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped" v-if="automation.model == 'Order'">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Number</th>
                      <th>Status</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="model in models.data" :key="model.id">
                      <td>{{model.id}}</td>
                      <td>{{model.number}}</td>
                      <td>
                        <span
                          class="badge ucfirst"
                          :class="{
                  'bg-secondary': model.status == 'pending' || model.status == 'awaiting_payment_confirmation',
                  'bg-success': model.status == 'processing',
                  'bg-primary': model.status == 'completed' || model.status == 'refunded',
                  'bg-danger': model.status == 'failed' || model.status == 'on_hold' || model.status == 'cancelled',
                }"
                        >{{model.status | formatStatus}}</span>
                      </td>
                      <td>{{model.billing_address.first_name}} {{model.billing_address.last_name}}</td>
                      <td>{{model.billing_address.email}}</td>
                      <td>{{formatValue(model.created_at, 'longDate')}}</td>
                      <td>{{formatValue(model.meta.payment_amount.cents, 'money')}}</td>
                      <td>
                        <button class="vave-btn btn-small btn-blue" :onclick="`window.open('/orders/`+model.id+`', '_blank')`">
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped" v-if="automation.model == 'Cart'">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>User</th>
                      <th>Items</th>
                      <th>Unique Items</th>
                      <th>Value</th>
                      <th>Last Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="model in models.data" :key="model.id">
                      <td>{{model.id}}</td>
                      <td>{{model.user ? model.user.email : ''}}</td>
                      <td>{{model.total_items}}</td>
                      <td>{{model.unique_items}}</td>
                      <td>{{formatValue(model.total_value_in_cents, 'money')}}</td>
                      <td>{{formatValue(model.updated_at, 'longDate')}} ({{formatValue(model.updated_at, 'diffForHumans')}})</td>
                    </tr>
                  </tbody>
                </table>
                <pagination class="paginator" :limit="4" align="center" :data="models" @pagination-change-page="getModels"></pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </general-modal>
  </div>
</template>

<script>
import axios from "./../../http.js";
import GeneralModal from "./../GeneralModal";
import { formatValue } from "./../../formatters";
import pagination from "laravel-vue-pagination";

export default {
  props: ["automation"],
  components: { GeneralModal, pagination },
  data() {
    return {
      models: {},
      loading: false,
      count: null,
      time: null,
      pretendedTime: null,
      totalModels: null,
    };
  },
  computed: {
    automationConditions() {
      return this.automation.conditions;
    },
  },
  watch: {
    pretendedTime() {
      this.getModels();
    },
    automation: {
      deep: true,
      handler() {
        // Check if the automation is changed and get how many models we should loop through when running the automation
        this.getModels();
      },
    },
    automationConditions: {
      handler() {
        this.getCount();
      },
      deep: true,
    },
  },
  filters: {
    formatStatus(value) {
      return value.replace(/_/g, " ");
    },
  },
  methods: {
    formatValue,
    getCount() {
      this.loading = true;
      axios
        .get("/api/admin/automations/triggers/preview", {
          params: {
            model: this.automation.model,
            time: this.pretendedTime,
            ignore_models_older_than_minutes:
              this.automation.configuration.ignore_models_older_than_minutes,
            conditions: this.automation.conditions
              ? this.automation.conditions
              : [],
            include_models: 0,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.count = response.data.total;
            this.totalModels = response.data.total_models;
          }
        });
    },
    previewModels() {
      this.getModels();
      this.$refs.modelsList.show();
    },
    getModels(page) {
      this.loading = true;
      this.models = {};
      axios
        .get("/api/admin/automations/triggers/preview", {
          params: {
            model: this.automation.model,
            time: this.pretendedTime,
            ignore_models_older_than_minutes:
              this.automation.configuration.ignore_models_older_than_minutes,
            conditions: this.automation.conditions
              ? this.automation.conditions
              : [],
            include_models: 1,
            page: page,
            per_page: 13,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.models = response.data;
            this.count = response.data.total;
            this.totalModels = response.data.total_models;
          }
        });
    },
    toggleTimePicker() {
      this.$refs.timepicker.classList.toggle("hidden");
      this.pretendedTime = null;
      this.time = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.link-icon {
  cursor: pointer;
  :hover {
    color: #2980b9;
  }
}
.hidden {
  display: none;
}
</style>