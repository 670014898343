<template>
  <div>
    <div class="background">
      <div class="row">
        <div class="col"></div>
        <div class="col-auto">
          <router-link :to="{ name: 'automationsSimulations' }" class="mb-4 vave-btn btn-outline-blue">SIMULATIONS</router-link>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'automations' }" class="mb-4 vave-btn">BACK TO AUTOMATIONS</router-link>
        </div>
      </div>
      <div class="row mt-4 pb-4" v-if="automationReady">
        <div class="col-6">
          <div class="bold"></div>
          <table class="table table-bordered table-striped table-sm align-middle">
            <tbody>
              <tr>
                <th colspan="2" class="text-center">EXECUTION INFORMATION</th>
              </tr>
              <tr>
                <th>Creation date</th>
                <td v-if="automation.created_at">{{formatValue(automation.created_at, 'longDate')}}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td class="d-flex align-items-center justify-content-between">
                  <select v-model="automation.status" @change="updateAutomationStatus()" class="form-select">
                    <option value="active" :selected="automation.status == 'active'">Active</option>
                    <option value="inactive" :selected="!automation.status == 'inactive'">Inactive</option>
                    <option value="testing" :selected="!automation.status == 'testing'">Testing</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Executed</th>
                <td class="d-flex justify-content-between">
                  <span>{{automation.total_count}} times</span>
                  <div class="link-icon" @click="completedAutomationsStatus = null, getCompletedAutomation()">
                    <i class="fa fa-eye"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Succeded</th>
                <td class="d-flex justify-content-between">
                  <span>
                    {{automation.succeeded_count}} times
                    <span v-if="automation.total_count">({{ formatValue(automation.succeeded_count * 100 / automation.total_count, 'percentage')}})</span>
                  </span>
                  <div class="link-icon" @click="completedAutomationsStatus = 'succeeded', getCompletedAutomation()">
                    <i class="fa fa-eye"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Failed</th>
                <td class="d-flex justify-content-between">
                  <span>
                    {{automation.failed_count}} times
                    <span v-if="automation.total_count">({{ formatValue(automation.failed_count * 100 / automation.total_count, 'percentage')}})</span>
                  </span>
                  <div class="link-icon" @click="completedAutomationsStatus = 'failed', getCompletedAutomation()">
                    <i class="fa fa-eye"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Pending</th>
                <td class="d-flex justify-content-between">
                  <span>{{automation.pending_count}} times</span>
                  <div class="link-icon" @click="completedAutomationsStatus = 'pending', getCompletedAutomation()">
                    <i class="fa fa-eye"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Succeeded in past 24 hours</th>
                <td class="d-flex justify-content-between">
                  <span>{{automation.succeeded_in_past_24_hours}} times</span>
                </td>
              </tr>
              <tr>
                <th>Last successful execution</th>
                <td>
                  <span v-if="automation.last_succeeded_time">{{formatValue(automation.last_succeeded_time, 'longDate')}} ({{formatValue(automation.last_succeeded_time, 'diffForHumans')}})</span>
                  <span v-else>never</span>
                </td>
              </tr>
              <tr>
                <th>Last failed execution</th>
                <td>
                  <span v-if="automation.last_failed_time">{{formatValue(automation.last_failed_time, 'longDate')}} ({{formatValue(automation.last_failed_time, 'diffForHumans')}})</span>
                  <span v-else>never</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="block block-with-arrow">
            <div class="row">
              <div class="col-4">
                Description:
                <input type="text" class="form-control" v-model="automation.description" />
              </div>
              <div class="col-auto">
                Model:
                <select v-model="automation.model" class="form-select" :disabled="true">
                  <option>{{automation.model}}</option>
                </select>
              </div>
              <div class="col-auto">
                <br />
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="shouldRunOnce" id="checkRunOnce" />
                  <label class="form-check-label" for="checkRunOnce">Run only once per Model</label>
                </div>
              </div>
              <div class="col-auto" :title="'total Models: ' + count">
                Ignore models older than:
                <select v-model="ignoreModelsOlderThanMinutes" class="form-select">
                  <!-- We add 1 hourish just to be safe the models are not missed -->
                  <option :value="null">-</option>
                  <option value="45">45 Minutes</option>
                  <option value="60">1 Hour</option>
                  <option value="90">1 Hour and half</option>
                  <option value="1440">1 Day</option>
                  <option value="2880">2 Day</option>
                  <option value="11520">8 Days</option>
                  <option value="21600">15 days</option>
                  <option value="44640">31 days</option>
                  <option value="87840">2 months</option>
                  <option value="262080">6 months</option>
                  <option value="525600">1 year</option>
                </select>
              </div>
              <div class="col-auto">
                Run every:
                <select v-if="automation.configuration" v-model="runEvery" class="form-select" :disabled="loadingCount">
                  <!-- We add 1 hourish just to be safe the models are not missed -->
                  <option value="minute" :disabled="disableRunEveryMinute">Minute</option>
                  <option value="hour">Hour</option>
                  <option value="day">Day (09:00)</option>
                  <option value="night">Night (00:00)</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="block block-with-arrow">
            <conditions-component v-if="automation.model" v-model="automation.conditions" :automationModel="automation.model" />
            <triggers-preview :automation="automation" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="block">
            <actions-component v-if="automation.model" @configurationchanged="automation.configuration = $event" @actionchanged="automation.action = $event" :automationModel="automation.model" :automationAction="automation.action" :automationConfiguration="automation.configuration" />
          </div>
        </div>
      </div>

      <div class="row mt-4 justify-content-start">
        <div class="col-auto" v-if="automation.configuration">
          <button class="vave-btn btn-outline-blue" @click="showConfiguration()" title="show raw configuration">
            <i class="fa fa-eye me-1"></i> Configuration
          </button>
          <button class="ms-1 vave-btn btn-outline-blue" v-if="automation.model == 'User'" @click="executeAutomation()" :disabled="testAutomationDisabled">
            <i class="fa fa-cogs me-1"></i> Test the automation
          </button>
        </div>
        <div class="col-auto">
          <button class="vave-btn btn-green" @click="saveAutomation()" :disabled="disableSaveButton">
            <i class="fa fa-save"></i>&nbsp;Update Automation
          </button>
        </div>
      </div>

      <general-modal ref="rawConfiguration">
        <template v-slot:title>Configuration</template>
        <template v-slot:body>
          <div style="border:1px solid black;padding: 20px;">
            <div style="overflow: auto;">
              <span style="white-space: pre;">{{automation.configuration}}</span>
            </div>
          </div>
        </template>
      </general-modal>

      <general-modal ref="completedAutomations" :fullWidth="true" :fullHeight="true">
        <template v-slot:title>Completed {{completedAutomationsStatus}} Automations</template>
        <template v-slot:body>
          <div style="overflow: auto;">
            <div class="row" v-if="completedAutomationsLoading">
              <div class="col">
                <div class="spinner-border spinner-border-sm ms-2" role="status"></div>
              </div>
            </div>
            <div v-else class="d-flex flex-column">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Model</th>
                    <th>Model ID</th>
                    <th>Status</th>
                    <th v-if="completedAutomationsStatus=='failed'">Failure Reason</th>
                    <th>Started at</th>
                    <th>Finished at</th>
                    <th>Execution Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="completedAutomation in completedAutomations.data" :key="completedAutomation.id">
                    <td>{{automation.model}}</td>
                    <td>{{completedAutomation.model_id}}</td>
                    <td>
                      <span
                        class="badge ucfirst"
                        :class="{
                          'bg-secondary': completedAutomation.status == 'pending',
                          'bg-success': completedAutomation.status == 'succeeded',
                          'bg-danger': completedAutomation.status == 'failed',
                        }"
                      >{{completedAutomation.status}}</span>
                    </td>
                    <td v-if="completedAutomationsStatus=='failed'">{{completedAutomation.failure_reason}}</td>
                    <td>{{formatValue(completedAutomation.started_at, 'longDate')}}</td>
                    <td>{{formatValue(completedAutomation.finished_at, 'longDate')}}</td>
                    <td>{{timeDifference(completedAutomation.started_at, completedAutomation.finished_at)}}</td>
                    <td v-if="automation.model == 'User'">
                      <button class="vave-btn btn-small btn-outline-blue" :onclick="`window.open('/customers/`+completedAutomation.model_id+`', '_blank')`">
                        <i class="fa fa-eye"></i>
                      </button>
                    </td>
                    <td v-if="automation.model == 'Order'">
                      <button class="vave-btn btn-small btn-outline-blue" :onclick="`window.open('/orders/`+completedAutomation.model_id+`', '_blank')`">
                        <i class="fa fa-eye"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination class="paginator" :limit="4" align="center" :data="completedAutomations" @pagination-change-page="getCompletedAutomation"></pagination>
            </div>
          </div>
        </template>
      </general-modal>
    </div>
  </div>
</template>

<script>
import WatchObject from "./WatchObject.vue";
import axios from "./../http.js";
import { formatValue } from "./../formatters";
import ConditionsComponent from "./automations/Conditions.vue";
import ActionsComponent from "./automations/Actions.vue";
import TriggersPreview from "./automations/TriggersPreview.vue";
import GeneralModal from "./GeneralModal";
import pagination from "laravel-vue-pagination";
import moment from "moment";

export default {
  extends: WatchObject,
  data() {
    return {
      automation: {},
      automationReady: false,
      runEvery: "day",
      disableSaveButton: false,
      completedAutomations: {},
      completedAutomationsLoading: false,
      completedAutomationsStatus: null,
      testAutomationDisabled: false,
      shouldRunOnce: false,
      ignoreModelsOlderThanMinutes: null,
      loadingCount: true,
      count: null,
    };
  },
  components: {
    ConditionsComponent,
    ActionsComponent,
    TriggersPreview,
    GeneralModal,
    pagination,
  },
  computed: {
    shouldRefreshModelsCount() {
      return this.automation.model + this.automation.configuration
        ? this.automation.configuration.ignore_models_older_than_minutes
        : "";
    },
    disableRunEveryMinute() {
      return this.count > 200;
    },
  },
  mounted() {
    this.getAutomation();
  },
  methods: {
    formatValue,
    getAutomation() {
      this.disableRefreshButton = true;
      axios.get("/api/admin/automations/" + this.$route.params.id).then(
        (r) => {
          if (r.status == 200) {
            this.automation = r.data.data;
          }
          this.shouldRunOnce = this.automation.configuration.should_run_once;
          this.ignoreModelsOlderThanMinutes =
            this.automation.configuration.ignore_models_older_than_minutes;
          this.runEvery = this.automation.configuration.run_every;
          this.automationReady = true;
          this.setObjectHash(this.automation);
          // this.getCompletedAutomation();
        },
        (e) => {
          console.log(e);
        }
      );
    },
    updateAutomationStatus() {
      if (
        !confirm(
          "Are you sure? IMPORTANT: this action will also save all the modified conditions/actions on this page"
        )
      )
        return;
      this.saveAutomation();
    },
    saveAutomation() {
      this.disableSaveButton = true;
      axios
        .put("/api/admin/automations/" + this.automation.id, {
          model: this.automation.model,
          action: this.automation.action,
          status: this.automation.status,
          description: this.automation.description,
          conditions: this.automation.conditions,
          configuration: this.automation.configuration,
        })
        .then(
          () => {
            setTimeout(() => {
              this.disableSaveButton = false;
            }, 1000);
            this.$toast.success("Automation saved");
            this.getAutomation();
          },
          (e) => {
            setTimeout(() => {
              this.disableSaveButton = false;
            }, 1000);
            if (e.response && e.response.data && e.response.data.errors) {
              this.errors = e.response.data.errors;
            }
            this.$toast.error("Error creating automation");
          }
        )
        .finally(() => {
          // Generate the automation hash so we can identify unsaved changes
          this.setObjectHash(this.automation);
        });
    },
    showConfiguration() {
      this.$refs.rawConfiguration.show();
    },
    executeAutomation() {
      if (
        !confirm(
          "IMPORTANT: save your changes before testing the automation, or they won't be reflected. This will execute the automation with the current user: " +
            this.$store.state.auth.user.email
        )
      ) {
        return;
      }
      this.testAutomationDisabled = true;
      axios
        .post("/api/admin/automations/" + this.automation.id + "/execute", {
          model: "User",
          model_id: this.$store.state.auth.user.id,
          ignore_conditions: true,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast.info(response.data.message);
            return;
          }
          console.log(response);
          this.$toast.warning(
            "Something wrong happened, check the console for more details"
          );
        })
        .finally(() => {
          setTimeout(() => {
            this.testAutomationDisabled = false;
          }, 3000);
        });
    },
    getCompletedAutomation(page) {
      this.$refs.completedAutomations.show();
      this.models = {};
      axios
        .get("/api/admin/automations/" + this.automation.id + "/completed", {
          params: {
            status: this.completedAutomationsStatus,
            page,
            per_page: 13,
          },
        })
        .then((response) => {
          this.completedAutomationsLoading = false;
          if (response.status == 200) {
            this.completedAutomations = response.data;
          }
        });
    },
    timeDifference(start, end) {
      let seconds = moment(end).diff(moment(start), "seconds");
      if (seconds > 60) {
        let minutes = Math.floor(seconds / 60);
        let restSeconds = seconds % 60;
        if (minutes > 60) {
          let restMinutes = minutes % 60;
          return (
            Math.floor(minutes / 60) +
            "h " +
            restMinutes +
            "min " +
            restSeconds +
            "sec"
          );
        }
        return Math.floor(seconds / 60) + "min " + restSeconds + "sec";
      }
      return seconds + " seconds";
    },
    getModelsCount() {
      this.loadingCount = true;
      axios
        .get("/api/admin/automations/triggers/preview", {
          params: {
            model: this.automation.model,
            ignore_models_older_than_minutes:
              this.automation.configuration.ignore_models_older_than_minutes,
            conditions: [],
            include_models: 0,
          },
        })
        .then((response) => {
          this.loadingCount = false;
          if (response.status == 200) {
            this.count = response.data.total;
          }
        });
    },
  },
  watch: {
    shouldRunOnce() {
      this.automation.configuration.should_run_once = this.shouldRunOnce;
    },
    ignoreModelsOlderThanMinutes() {
      this.automation.configuration.ignore_models_older_than_minutes =
        this.ignoreModelsOlderThanMinutes;
    },
    runEvery() {
      this.automation.configuration.run_every = this.runEvery;
    },
    automation: {
      deep: true,
      handler(val) {
        this.automation.configuration.run_every = this.runEvery;
        this.automation.configuration.should_run_once = this.shouldRunOnce;
        this.automation.configuration.ignore_models_older_than_minutes =
          this.ignoreModelsOlderThanMinutes;
        this.notifyUnsavedChanges(val);
      },
    },
    shouldRefreshModelsCount() {
      this.getModelsCount();
    },
    disableRunEveryMinute() {
      if (this.runEvery == "minute") {
        this.runEvery = "hour";
        this.$toast.warning(
          "The automation is too computationally expensive to run every minute. If you really need to run it every minute, try to ignore older models."
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
.link-icon {
  cursor: pointer;
  :hover {
    color: #2980b9;
  }
}
.block {
  background: #f0f0f0;
  padding: 20px;
  margin-bottom: 10px;
  // border: 1px solid black;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.block-with-arrow::after {
  content: "";
  position: absolute;
  left: 42%;
  bottom: -16px;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  background: #f0f0f0;
  z-index: 1;
  border: 1px solid #ddd;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
}
</style>