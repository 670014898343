<template>
  <div class="modal-wrapper" :class="{ showing: showing }">
    <div class="modal-container" :class="{'full-width': fullWidth, 'full-height': fullHeight}">
      <div class="modal-header">
        <div class="modal-title">
          <slot name="title"></slot>
        </div>
        <button class="btn btn-sm btn-danger text-white" @click="showing = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showing: false,
      icon: "",
    };
  },
  methods: {
    show() {
      this.showing = true;
    },
    hide() {
      this.showing = false;
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.showing && e.keyCode === 27) {
        this.hide();
      }
    };
    document.addEventListener("keydown", onEscape);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
};
</script>

<style lang="scss">
.modal-wrapper {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 3;
  top: 0px;
  height: 100vh;
  left: 0px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease-in-out;

  label {
    font-weight: bold;
  }

  .modal-container {
    background: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    max-width: 1000px;
    &.full-width {
      max-width: 98vw;
    }

    height: calc(100vh - 200px);
    &.full-height {
      height: calc(100vh - 50px);
    }
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 50px 50px rgba(0, 0, 0, 0.5);

    .modal-header {
      width: 100%;
      background: rgb(47, 47, 48);
      color: white;
      border-radius: 8px 8px 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .modal-title {
        font-size: 1.4em;
        font-weight: bold;
        margin-left: 10px;
      }
    }
    .modal-body {
      flex-grow: 2;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px;
    }
  }

  &.showing {
    opacity: 1;
    pointer-events: all;
  }
}
</style>